import React, { useMemo } from 'react';

import useAuthStore from '@/src/hooks/auth';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import DashboardButton from '@/src/ui/DashboardButton/DashboardButton';
import clsx from 'clsx';
import { shallow } from 'zustand/shallow';
import styles from './DropdownMemberItem.module.scss';
import { SimplifiedMember } from './MemberBar';

const DropdownMemberItem: React.FC<{
  member: SimplifiedMember;
  anonId?: string; // in case the user is not logged in
  following: string | null | undefined;
  spotlightSelf: (e: React.MouseEvent) => void;
  followUser: (userId: string, e?: React.MouseEvent) => void;
}> = ({ member, anonId, following, spotlightSelf, followUser }) => {
  const user = useAuthStore((state) => state.user, shallow);

  const isSelf = member.id === user?.id || member.id === anonId;
  const buttonLabel = useMemo(() => {
    if (isSelf) return 'Spotlight';
    if (following === member.id) return 'Unfollow';
    return 'Follow';
  }, [isSelf, following, member.id]);

  return (
    <div className={styles.item}>
      <AvatarUser user={member} size={28} className={!member.online ? styles.offline : undefined} />
      <div className={clsx(styles.name, !member.online && styles.offline)}>{member.name}</div>
      {member.online && (
        <DashboardButton
          color="black"
          border
          size="small"
          onClick={(e) => (isSelf ? spotlightSelf(e) : followUser(member.id, e))}
          style={{
            marginLeft: 'auto',
          }}
        >
          {buttonLabel}
        </DashboardButton>
      )}
    </div>
  );
};

export default DropdownMemberItem;
