import { useAuthUser } from '@/src/hooks/auth';
import { withConfirmation } from '@/src/lib/react-query/utilities';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getWoodyResponseData } from '../../../services/woody/utils';
import { useQueryCacheMemberHelpers } from '../../members/utils/useQueryCacheMembersHelpers';
import { resourceQueryPredicates } from '../../resources/queries/resourceQueryPredicates';
import { useQueryCacheSpaceHelpers } from '../utils/useQueryCacheSpaceHelpers';

type SpaceDeleteOrLeaveActions = 'expanded-space' | 'space-list' | 'space-preview';

type MutationVariables = {
  space: { id: string; isOwner: boolean };
  disableToast?: boolean;
  action?: SpaceDeleteOrLeaveActions;
};

export const useMutationDeleteOrLeaveSpace = () => {
  const user = useAuthUser();
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { deleteCachedSpaces } = useQueryCacheSpaceHelpers();
  const { removeSelfFromCachedSpace } = useQueryCacheMemberHelpers();

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ space }: MutationVariables) => {
      if (!user) throw new Error('User not found');

      getWoodyResponseData(
        space.isOwner
          ? await client.deleteList(space.id)
          : await client.leaveList(space.id, user.id),
      );

      return null;
    },
    onMutate: ({ space }) => {
      return {
        deleteCachedSpacesContext: deleteCachedSpaces([space.id]),
        removeSelfFromCachedSpaceContext: removeSelfFromCachedSpace(space.id),
      };
    },
    onSuccess: (_, { space, disableToast, action = 'space-preview' }) => {
      if (!disableToast)
        toast({
          content: `Space ${space.isOwner ? 'deleted' : 'left'}`,
        });

      track(space.isOwner ? AnalyticsEvents.DeletedSpace : AnalyticsEvents.LeftSpace, {
        spaceId: space.id,
        action,
      });

      queryClient.invalidateQueries({
        predicate: (q) =>
          // Leaving a space means resources within the space are no longer available
          resourceQueryPredicates.filterAndSearchAll(q) || resourceQueryPredicates.summaryAll(q),
        type: 'active',
      });
    },
    onError: (_, { space, disableToast }, context) => {
      if (!disableToast)
        toast({
          content: `Failed to ${space.isOwner ? 'delete' : 'leave'} space`,
        });

      context?.deleteCachedSpacesContext?.resetCacheToPreOptimisticState();
      context?.deleteCachedSpacesContext?.invalidateQueries();
      context?.removeSelfFromCachedSpaceContext?.resetCacheToPreOptimisticState();
      context?.removeSelfFromCachedSpaceContext?.invalidateQueries();
    },
  });

  return withConfirmation(mutation, ({ space }) => {
    return {
      title: `${space.isOwner ? 'Delete' : 'Leave'} space - Are you sure?`,
      content: `Are you sure you want to ${space.isOwner ? 'delete' : 'leave'} this space? ${space.isOwner ? 'This action cannot be undone.' : ''}`,
      confirmLabel: `Yes, ${space.isOwner ? 'Delete' : 'Leave'}`,
      cancelLabel: 'Cancel',
      confirmButtonProps: {
        variant: 'danger',
      },
    };
  });
};
