import React, { useEffect } from 'react';
import styles from './DropdownMemberList.module.scss';
import { createPortal } from 'react-dom';
import { Position } from '@/src/types/global';
import { useOnClickOutside } from '@/src/hooks/outside';
import DropdownMemberItem from './DropdownMemberItem';
import { SimplifiedMember } from './MemberBar';

const DropdownMemberList: React.FC<{
  simplifiedMembers: SimplifiedMember[];
  anonId?: string; // in case the user is not logged in
  position: Position;
  setDropdownOpen: (open: boolean) => void;

  following: string | null | undefined;
  spotlightSelf: (e: React.MouseEvent) => void;
  followUser: (userId: string, e?: React.MouseEvent) => void;
}> = ({
  simplifiedMembers,
  anonId,
  position,
  setDropdownOpen,
  following,
  followUser,
  spotlightSelf,
}) => {
  const [ref, setRef] = React.useState<HTMLDivElement | null>(null);
  useOnClickOutside(ref, () => setDropdownOpen(false));

  const [offsetLeft, setOffsetLeft] = React.useState(0);

  useEffect(() => {
    if (!ref) return;

    // if we are outside the window, move the dropdown to the left with the offset
    const calculateOffset = () => {
      const rect = ref.getBoundingClientRect();
      const offset = rect.left + rect.width - window.innerWidth + 10;
      if (offset > 0) {
        setOffsetLeft(offset);
      } else if (offset < -20) {
        setOffsetLeft(0);
      }
    };

    calculateOffset();
    window.addEventListener('resize', calculateOffset);
    return () => window.removeEventListener('resize', calculateOffset);
  }, [ref]);

  const target = document.getElementById('tooltip-portal');
  return !target
    ? null
    : createPortal(
        <div
          style={{
            top: position.y,
            left: position.x - offsetLeft,
          }}
          className={styles.container}
          ref={setRef}
        >
          {simplifiedMembers.map((m) => (
            <DropdownMemberItem
              key={m.id}
              member={m}
              anonId={anonId}
              followUser={followUser}
              spotlightSelf={spotlightSelf}
              following={following}
            />
          ))}
        </div>,
        target
      );
};

export default DropdownMemberList;
