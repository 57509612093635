import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { Fdoc, convertFabricSearchHitToFdoc } from '@/src/types/api';
import { FabricQueryMode, FabricSearchQueryKeyword } from '@fabric/woody-client';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { spaceQueryKeys } from './spacesQueryKeys';

type UseQuerySpaceWebnotesSearchQueryOptions = {
  /**
   * how many items should be fetched
   * default is 100
   */
  limit?: number;
};

/**
 * how many items to load
 */
const LIMIT_WEBNOTES = 100;

/**
 * loads up to 100 webnotes for given space id
 *
 * @param spaceId
 * @param queryOptions
 * @returns
 */
export const useQuerySpaceWebnotes = (
  spaceId?: string,
  searchQueryOptions?: UseQuerySpaceWebnotesSearchQueryOptions,
  queryOptions?: Partial<QueryObserverOptions<Fdoc[]>>,
) => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();

  const query = useQuery({
    queryKey: spaceQueryKeys.spaceWebnoteResources(spaceId),
    queryFn: async (params) => {
      /**
       * query is not enabled if the spaceId is not provided
       * using it here should be considered safe
       */
      const spaceIdSafe = params.queryKey[1] as string;

      const searchQuery: FabricSearchQueryKeyword = {
        mode: FabricQueryMode.Keyword,
        text: '',
        filters: {
          parent: [spaceIdSafe],
          list: [spaceIdSafe],
          hasSlug: false,
          type: ['webnote'],
        },
        sort: {
          field: 'createdAt',
          order: 'desc',
        },
        pagination: {
          page: 1,
          pageSize: searchQueryOptions?.limit ?? LIMIT_WEBNOTES,
        },
      };

      const response = await client.searchV2(searchQuery);

      if (response.error) {
        throw response.error;
      }

      return response.data.hits.map(convertFabricSearchHitToFdoc);
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
    ...queryOptions,
    enabled: isQueryEnabled([isLoggedIn, queryOptions?.enabled, !!spaceId]),
  });

  return query;
};
