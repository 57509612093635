import useAuthStore from '@/src/hooks/auth';
import { Member } from '@/src/modules/members/members.types';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import { SpaceOverviewViewMode } from '@/src/store/ui';
import { Position } from '@/src/types/global';
import { DashboardUserMeta } from '@/src/types/presence';
import { getUserColorFromId } from '@/src/utils/color';
import { JsonObject, User } from '@liveblocks/client';
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import DropdownMemberList from './DropdownMemberList';
import styles from './MemberBar.module.scss';
import UserTooltip from './UserTooltip';

export type MemberData = Omit<Member, 'createdAt' | 'modifiedAt'>;

export type SimplifiedMember = {
  id: string;
  name: string;
  picture: string | null | undefined;
  color: string;
  role: string;
  online: boolean;
};

const simplifyMember = (
  member: MemberData | User<JsonObject, DashboardUserMeta>,
  liveMembers: User<JsonObject, DashboardUserMeta>[] | undefined = undefined,
): SimplifiedMember => {
  const fromLiveblocks = 'info' in member;

  return {
    id: member.id,
    name: fromLiveblocks ? member.info.name : member.name,
    picture: fromLiveblocks ? member.info.picture : member.picture,
    color: getUserColorFromId(member.id),
    role: fromLiveblocks ? 'anonymous' : member.role,
    online: fromLiveblocks || !!liveMembers?.some((m) => m.id === member.id),
  };
};

interface MemberBarPropsBase {
  anonId?: string; // in case the user is not logged in
  small?: boolean;
  followUser?: (userId: string, e?: React.MouseEvent) => void;
  spotlightSelf?: (e: React.MouseEvent) => void;
  following?: string | null;
  className?: string;
  maxMembers?: number;
  noBorder?: boolean;
  spaceOverviewViewMode?: SpaceOverviewViewMode;
  hideWhenAlone?: boolean;
}

/**
 * use this set of props if you're possibly not passing full list of members
 * but you know total members. Good if you don't want to overfetch and no need to display online members
 *
 */
interface MemberBarPropsWithPartialMemberList extends MemberBarPropsBase {
  partialMembersList: MemberData[];
  totalMembers?: number;
}
/**
 * Use this set of props if you know all members in the space.
 * Include liveMembers if you want to show online status.
 *
 * total members will deduct the intersection of members and liveMembers to show correct count
 */
interface MemberBarPropsWithAllMembers extends MemberBarPropsBase {
  members: MemberData[];
  liveMembers?: User<JsonObject, DashboardUserMeta>[];
}

type MemberBarProps = MemberBarPropsWithAllMembers | MemberBarPropsWithPartialMemberList;

const MemberBar: React.FC<MemberBarProps> = ({
  anonId,
  small,
  followUser = () => {},
  spotlightSelf = () => {},
  following,
  className,
  maxMembers = 3,
  noBorder = false,
  spaceOverviewViewMode,
  hideWhenAlone = false,
  ...props
}) => {
  const [ref, setRef] = React.useState<HTMLDivElement | null>(null);
  const user = useAuthStore((state) => state.user, shallow);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [dropdownPosition, setDropdownPosition] = React.useState<Position | null>(null);

  const liveMembers = 'liveMembers' in props ? props.liveMembers : [];
  const members = 'members' in props ? props.members : props.partialMembersList;

  useEffect(() => {
    if (!ref) return;

    const calculatePosition = () => {
      const rect = ref.getBoundingClientRect();
      const x = rect.left + rect.width / 2;
      const y = rect.top + rect.height + 10;

      setDropdownPosition({ x, y });
    };

    calculatePosition();

    window.addEventListener('resize', calculatePosition);
  }, [ref]);

  const simplifiedMembers = useMemo(() => {
    if (!members && !liveMembers) return [];
    if (!liveMembers && members)
      return members.map((member) => simplifyMember(member, liveMembers));

    const allMembers = [...(members ?? []), ...(liveMembers ?? [])]
      .map((member) => simplifyMember(member, liveMembers))
      .filter((m, _, arr) => arr.findIndex((a) => a.id === m.id) === arr.lastIndexOf(m));

    // sort if it's self or online (self has priority)
    const sortedMembers = allMembers.sort((a, b) => {
      if (a.id === user?.id) return -1;
      if (b.id === user?.id) return 1;

      if (a.online && !b.online) return -1;
      if (!a.online && b.online) return 1;

      return 0;
    });

    return sortedMembers;
    // Clear out any duplicates
  }, [members, liveMembers, user?.id]);

  const totalMembers = 'totalMembers' in props ? props.totalMembers || 0 : simplifiedMembers.length;

  const hiddenMembersCount = useMemo(() => {
    return Math.max(0, totalMembers - maxMembers);
  }, [totalMembers, maxMembers]);

  const handleOnClick = (e: React.MouseEvent) => {
    if (!liveMembers) return;

    // if the target is not a child of ref ignore
    if (!ref?.contains(e.target as Node) && e.target !== ref) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    setDropdownOpen(!dropdownOpen);
  };

  /**
   * If there are no diff members, or if there is only one member and it's the user,
   * then we don't need to show the member bar.
   */
  const isAlone =
    (simplifiedMembers.length === 1 && simplifiedMembers[0].id === user?.id) ||
    simplifiedMembers.length === 0;

  if (
    (!members && !liveMembers) ||
    (members?.length === 0 && liveMembers?.length === 0) ||
    (isAlone && hideWhenAlone)
  )
    return null;

  return (
    <div
      className={clsx(
        styles.bar,
        small && styles.small,
        simplifiedMembers.length === 1 && styles.single,
        className,
      )}
      data-test-container="member-bar"
      style={{
        cursor: liveMembers ? 'pointer' : 'default',
        border: noBorder || spaceOverviewViewMode === 'List' ? 'none' : undefined,
      }}
      ref={setRef}
      onClick={handleOnClick}
    >
      {simplifiedMembers.slice(0, maxMembers).map((member) => (
        <AvatarUser
          user={member}
          allowTooltipHover={liveMembers ? true : false}
          key={member.id}
          className={clsx(styles.member, liveMembers && !member.online && styles.offline)}
          size={small || spaceOverviewViewMode === 'List' ? 22 : 32}
          tooltip={
            <UserTooltip
              member={member}
              followUser={followUser}
              spotlightSelf={spotlightSelf}
              following={following}
              anonId={anonId}
            />
          }
        />
      ))}
      {hiddenMembersCount > 0 && (
        <span className={styles.hiddenMembers}>+{hiddenMembersCount}</span>
      )}

      {members && liveMembers && dropdownPosition && dropdownOpen && (
        <DropdownMemberList
          simplifiedMembers={simplifiedMembers}
          position={dropdownPosition}
          setDropdownOpen={setDropdownOpen}
          followUser={followUser}
          spotlightSelf={spotlightSelf}
          following={following}
          anonId={anonId}
        />
      )}
    </div>
  );
};

export default MemberBar;
