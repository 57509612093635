import React, { useMemo } from 'react';
import { SimplifiedMember } from './MemberBar';
import DashboardButton from '@/src/ui/DashboardButton/DashboardButton';
import { useInsideTooltip } from '@/src/ui/Tooltip';
import useAuthStore from '@/src/hooks/auth';
import { shallow } from 'zustand/shallow';

const UserTooltip: React.FC<{
  member: SimplifiedMember;
  following: string | null | undefined;
  spotlightSelf: (e: React.MouseEvent) => void;
  followUser: (userId: string, e?: React.MouseEvent) => void;
  anonId?: string; // in case the user is not logged in
}> = ({ member, following, spotlightSelf, followUser, anonId }) => {
  const { setOpen } = useInsideTooltip();
  const user = useAuthStore((state) => state.user, shallow);

  const onClick = (e: React.MouseEvent) => {
    setOpen(false);
    return member.id === user?.id || member.id === anonId
      ? spotlightSelf(e)
      : followUser(member.id, e);
  };

  const buttonLabel = useMemo(() => {
    if (member.id === user?.id || member.id === anonId) {
      return 'Spotlight';
    }
    if (following === member.id) {
      return 'Unfollow';
    }

    return 'Follow';
  }, [anonId, following, member.id, user?.id]);

  return (
    <div>
      {member.name} {member.role === 'owner' && `(${member.role})`}
      {member.online && (
        <DashboardButton
          size="small"
          onClickCapture={onClick}
          style={{
            width: '100%',
            marginTop: '9px',
            justifyContent: 'center',
          }}
        >
          {buttonLabel}
        </DashboardButton>
      )}
    </div>
  );
};

export default UserTooltip;
