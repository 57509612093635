import useOpenInTabs from '@/src/hooks/useOpenInTabs';
import { IconTrash, IconUserPlus } from '@/src/modules/icons';
import { ApiRootUserRole } from '@/src/modules/resource-roots/resource-roots.types';
import { ContextMenu } from '@/src/modules/ui/components/ContextMenu/ContextMenu';
import { DropdownItemIconContainer } from '@/src/modules/ui/components/DropdownMenu/Item';
import React from 'react';

export type SpacePreviewContextMenuProps = {
  handleDeleteOrLeaveClick: VoidFunction;
  currentUserRole: ApiRootUserRole;
  handleOpenInTabs: VoidFunction;
  openSharingModal: VoidFunction;
  isSpacePublic?: boolean;
  children: React.ReactElement;
  contextDisabled?: boolean;
};

export const SpacePreviewContextMenu: React.FC<SpacePreviewContextMenuProps> = ({
  children,
  handleDeleteOrLeaveClick,
  currentUserRole,
  handleOpenInTabs,
  openSharingModal,
  isSpacePublic,
  contextDisabled,
}) => {
  const { fabricExtensionInstalled } = useOpenInTabs();

  return (
    <ContextMenu
      contextDisabled={contextDisabled}
      content={
        <>
          <ContextMenu.Group>
            {fabricExtensionInstalled && (
              <>
                <ContextMenu.Item
                  data-testid="simple-space-context-menu-open-in-new-window"
                  onClick={handleOpenInTabs}
                >
                  Open all in new window
                </ContextMenu.Item>
                <ContextMenu.Separator />
              </>
            )}
            <ContextMenu.Item
              onClick={openSharingModal}
              data-testid="item-context-menu-share-button"
            >
              <DropdownItemIconContainer>
                <IconUserPlus />
              </DropdownItemIconContainer>
              {isSpacePublic ? 'Manage sharing' : 'Share'}
            </ContextMenu.Item>
          </ContextMenu.Group>
          <ContextMenu.Group>
            <ContextMenu.Item
              data-testid="simple-space-context-menu-delete-or-leave"
              onClick={handleDeleteOrLeaveClick}
              variant="danger"
            >
              <DropdownItemIconContainer>
                <IconTrash />
              </DropdownItemIconContainer>
              {currentUserRole === 'owner' ? 'Delete' : 'Leave'}
            </ContextMenu.Item>
          </ContextMenu.Group>
        </>
      }
    >
      {children}
    </ContextMenu>
  );
};
