import {
  SpacePreviewContextMenu,
  SpacePreviewContextMenuProps,
} from '@/src/components/SimpleSpace/SpacePreviewContextMenu';
import { useResponsive } from '@/src/hooks/responsive';
import { useBoolState } from '@/src/hooks/useBooleanState';
import useOpenInTabs from '@/src/hooks/useOpenInTabs';
import EmptyState from '@/src/icons/EmptyState';

import { useAuthUser } from '@/src/hooks/auth';
import { ResourceRoot } from '@/src/modules/resource-roots/resource-roots.types';
import { IconSharedWithPublic } from '@/src/modules/resources/components/IconSharedWithPublic';
import { useDroppableAsTargetToResourceMove } from '@/src/modules/resources/hooks/useDroppableAsTargetToResourceMove';
import { useQueryResources } from '@/src/modules/resources/queries/useQueryResources';
import { defaultResourceFilterOptions } from '@/src/modules/resources/resources.config';
import { useMutationDeleteOrLeaveSpace } from '@/src/modules/spaces/mutations/useMutationDeleteOrLeaveSpace';
import { useQuerySpaceWebnotes } from '@/src/modules/spaces/queries/useQuerySpaceWebnotes';
import { Flex } from '@/src/modules/ui/components/Flex';
import useMobileSelectionStore from '@/src/store/mobileSelection';
import { SpaceOverviewViewMode, useSearchOverlay } from '@/src/store/ui';
import { OptimisticDraft } from '@/src/types/draftable';
import { isInteractiveElement } from '@/src/utils/elements';
import clsx from 'clsx';
import { useInView } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import ShareModal from '../../modules/ui/components/ShareModal';
import MemberBar, { MemberData } from '../MemberBar/MemberBar';
import NoteStack from '../NoteStack/NoteStack';
import styles from './SimpleSpace.module.scss';

const SimpleSpace: React.FC<{
  /**
   * you want to disable the hover state e.g. during multiselect
   */
  disableHoverState?: boolean;
  rootSpace: OptimisticDraft<ResourceRoot>;

  simplified?: boolean;
  tilt?: boolean;
  selected?: boolean;
  // Forced mobile style
  forcedCompact?: boolean;
  spaceOverviewViewMode?: SpaceOverviewViewMode;
  handleSelectSpace?: (spaceId: string) => void;
  resourceStackScaleMobile?: number;

  /**
   * can dnd resources be dropped on this space?
   * false by default
   */
  enableDroppableTargetForResources?: boolean;
  disabled?: boolean;
}> = ({
  disableHoverState,
  rootSpace,
  simplified,
  tilt = false,
  selected = false,
  forcedCompact = false,
  spaceOverviewViewMode,
  handleSelectSpace,
  enableDroppableTargetForResources = false,
  resourceStackScaleMobile = 0.3,
  disabled = false,
}) => {
  const containerRef = React.useRef<HTMLElement | null>(null);
  const user = useAuthUser();

  /**
   * drop resources on me
   */

  const { styles: draggingOverStyles, droppableProps: droppablePropertiesProperties } =
    useDroppableAsTargetToResourceMove(
      containerRef,
      {
        id: rootSpace.id,
        type: 'space',
      },
      {
        enabled: enableDroppableTargetForResources,
        action: 'drop-into-space',
      },
    );

  /**
   * mouse over state
   */
  const mouseOverState = useBoolState();

  const { isOverlayOpen: isSearchOverlayOpen } = useSearchOverlay();

  /**
   *
   */
  const isInView = useInView(containerRef);

  /**
   * preview resources
   */
  const querySpaceResourcesPreview = useQueryResources(
    {
      ...defaultResourceFilterOptions,
      filters: {
        ...defaultResourceFilterOptions.filters,
        parent: rootSpace.folder.id,
      },
      perPage: 8, // fetching more just to filter out folders because we can't send specific filter to exclude folders
    },
    {
      enabled: Boolean(isInView && !isSearchOverlayOpen && rootSpace.folder.id),
      staleTime: 1000 * 60 * 2,
    },
  );

  /**
   * this is used in the preview
   */
  const resourcesWithoutFolders = querySpaceResourcesPreview.resources.filter(
    (item) => item.type !== 'folder',
  );
  const spaceResources = resourcesWithoutFolders;

  /**
   * webnotes - open in tabs feature
   */
  const querySpaceWebnotes = useQuerySpaceWebnotes(rootSpace.folder.id, undefined, {
    // disabled by default should be fetched only on demand
    enabled: false,
  });

  const { openInTabs, confirmationModal } = useOpenInTabs();

  const handleOpenInTabs = async () => {
    const webnotesRes = await querySpaceWebnotes.refetch();
    if (webnotesRes.data) {
      openInTabs(webnotesRes.data, { newWindow: true });
    }
  };

  const members: MemberData[] = useMemo(() => {
    const result = (rootSpace.folder.sharedWith?.members || [])?.map((sharedWith) => ({
      id: sharedWith.id,
      name: sharedWith.name || '??',
      picture: sharedWith.pictureUrlCdn || null,
      online: false,
      role: sharedWith.permission.role,
    }));
    if (user) {
      result.unshift({
        name: user.name || '??',
        id: user.id!,
        picture: user.pictureUrlCdn || null,
        online: true,
        role: rootSpace.folder.permissions.role,
      });
    }
    return result;
  }, [rootSpace.folder.sharedWith?.members, rootSpace.folder.permissions.role, user]);

  const totalMembers = rootSpace.folder.sharedWith?.totalMembers;

  const { mobileSelectModeIsActive } = useMobileSelectionStore(
    (state) => ({
      mobileSelectModeIsActive: state.mobileSelectModeIsActive,
    }),
    shallow,
  );

  const { isMobileView } = useResponsive();
  const isCompact = isMobileView || forcedCompact;

  const router = useRouter();

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const mutationDeleteOrLeaveSpace = useMutationDeleteOrLeaveSpace();

  const handleDeleteOrLeaveClick = () => {
    if (!rootSpace) return;

    mutationDeleteOrLeaveSpace.confirmAndMutate({
      space: {
        id: rootSpace.id,
        isOwner: rootSpace.folder.permissions.role === 'owner',
      },
    });
  };

  const isInbox = rootSpace.subtype === 'inbox';

  const onMouseOver = () => {
    if (!rootSpace || !('id' in rootSpace) || simplified) return;
    try {
      if (isInbox) {
        router.prefetch('/saved');
      } else {
        router.prefetch(`/spaces/${rootSpace.folder.id}`);
      }
    } catch (e) {
      // prefetching cancelled, probably clicked on something else before this promise could be fullfileed
      // ignore
    }
  };

  const onMouseClick = (e: React.MouseEvent) => {
    if (mobileSelectModeIsActive && handleSelectSpace && rootSpace && !isInbox) {
      handleSelectSpace(rootSpace.id);
      return;
    }

    if (e.button !== 0) return;
    if (isInteractiveElement(e.target as HTMLElement)) return;
    // if it's not a DOM child of the section or the section itself, don't navigate
    if (
      !containerRef?.current?.contains(e.target as HTMLElement) &&
      containerRef.current !== e.target
    )
      return;

    if (isInbox) {
      router.push('/saved');
      return;
    }
    if (!rootSpace || !('id' in rootSpace) || simplified) return;

    router.push(`/spaces/${rootSpace.folder.id}`);
  };

  const onShareModalClose = (e?: React.SyntheticEvent) => {
    e?.stopPropagation();
    setShareModalOpen(false);
  };

  const spaceTitle = useMemo(() => {
    if (isInbox) return 'Inbox';
    return rootSpace.folder.name || 'Untitled';
  }, [isInbox, rootSpace.folder.name]);

  const contextMenuProps: Omit<SpacePreviewContextMenuProps, 'children'> = {
    handleDeleteOrLeaveClick,
    currentUserRole: rootSpace.folder.permissions.role,
    handleOpenInTabs,
    openSharingModal: () => {
      setShareModalOpen(true);
    },
    /**
     * @TODO FUT-4725
     */
    isSpacePublic: true,
  };

  const animateNoteStack = !mobileSelectModeIsActive && mouseOverState.value;

  const memberBarProps = {
    partialMembersList: members,
    totalMembers,
  };

  const element =
    spaceOverviewViewMode === 'List' ? (
      <section
        className={clsx(
          styles.card__list__view__mode,
          simplified && styles.simplified,
          selected && styles.selected,
          isInbox && styles.isInbox,
          forcedCompact && styles.forcedMobile,
          {
            [styles.disabled]: disabled,
          },
        )}
        onMouseOver={onMouseOver}
        onClick={onMouseClick}
        ref={containerRef}
        data-testid={`space-item-[${rootSpace.folder.name}]`}
        data-collection-id={rootSpace.id}
        data-selectable-id={isInbox ? false : rootSpace.id}
      >
        <div className={styles.notestack__and__title__wrapper}>
          {spaceResources && spaceResources.length > 0 ? (
            <div className={styles.icon_wrapper}>
              <NoteStack
                fdocs={spaceResources}
                noTilt={!tilt}
                scale={0.15}
                verticalSpread={0.5}
                opacityTransition={!simplified}
              />
            </div>
          ) : (
            <div className={styles.icon_wrapper}>
              <EmptyState className={styles.empty__icon} />
            </div>
          )}
          {spaceTitle}
        </div>
        <Flex alignItems="center" gap="element">
          <MemberBar
            {...memberBarProps}
            small={!simplified}
            spaceOverviewViewMode={spaceOverviewViewMode}
            hideWhenAlone
          />
          {rootSpace.folder.isPublic && <IconSharedWithPublic />}
        </Flex>
      </section>
    ) : (
      <section
        onMouseEnter={mouseOverState.handleTrue}
        onMouseLeave={mouseOverState.handleFalse}
        id={rootSpace?.id}
        className={clsx(styles.card, {
          [styles.simplified]: simplified,
          [styles.forcedMobile]: forcedCompact,
          [styles.isInbox]: isInbox,
          [styles.selected]: selected,
          [styles.disabledHover]: disableHoverState,
          [styles.disabled]: disabled,
          [styles.draft]: rootSpace?.isDraft,
          [styles.mobile_wrapper_new]: isCompact,
        })}
        onMouseOver={onMouseOver}
        onClick={onMouseClick}
        ref={containerRef}
        data-testid={`space-item-[${rootSpace?.folder.name}]`}
        data-collection-id={rootSpace?.id}
        data-selectable-id={isInbox ? undefined : rootSpace?.id}
        style={draggingOverStyles}
        {...droppablePropertiesProperties}
      >
        {isInView && (
          <>
            <header className={styles.header} data-or-obscured>
              <h2 className={styles.title}>{spaceTitle}</h2>
            </header>

            <div className={styles.content}>
              {spaceResources && (
                <NoteStack
                  isHoveredView={animateNoteStack}
                  fdocs={isCompact && isInbox ? [] : spaceResources} // dont show on compact inbox
                  noTilt={!tilt}
                  scale={isCompact ? resourceStackScaleMobile : 0.56}
                  horizontalSpread={isCompact ? 2 : undefined}
                  verticalSpread={isCompact ? 0.6 : 0.7}
                  opacityTransition={!simplified}
                  horizontal={isCompact}
                />
              )}
            </div>

            <footer className={styles.footer}>
              {rootSpace.folder.isPublic && (
                <div style={members.length > 1 ? { marginRight: -6 } : undefined}>
                  <IconSharedWithPublic style={{ height: 23, width: 23 }} />
                </div>
              )}
              <StyledMemberBar
                {...memberBarProps}
                small={!simplified}
                spaceOverviewViewMode={spaceOverviewViewMode}
                hideWhenAlone
                maxMembers={isCompact ? 3 : 8}
              />
            </footer>
          </>
        )}
      </section>
    );

  return (
    <>
      <SpacePreviewContextMenu
        contextDisabled={selected || isInbox || rootSpace?.isDraft}
        {...contextMenuProps}
      >
        {element}
      </SpacePreviewContextMenu>
      {confirmationModal}
      {shareModalOpen &&
        rootSpace?.type === 'SPACE' &&
        rootSpace.subtype !== 'inbox' &&
        members &&
        members.length > 0 &&
        !simplified && <ShareModal spaceId={rootSpace.id} onClose={onShareModalClose} />}
    </>
  );
};

export default SimpleSpace;

const StyledMemberBar = styled(MemberBar)`
  padding: 2px;
  height: auto;
  border: none;
`;
